/*
 * Discription:陌生客户
 * -----
 * Created Date: 2019-08-23 03:39:31
 * Author: 郭兵 (guobing93@163.com)
 * -----
 * Last Modified: 2019-08-23 03:40:03
 * Modified By: name (email)
 */

<template>
    <div class="StrangeCustomer">
        <div class="hintBox">
            <i class="warnIcon iconfont icon-warning"></i>
            <p class="mailShow">
                {{mailAddress}}
            </p>
            <p class="hintText">
                <!-- 是一个陌生客户，抓住机会<br>
                立即【新增客户】或【新增联系人】 -->
                {{$t('mx_mobile.Client.1627021876633')}}
                <br>
                {{$t('mx_mobile.Client.1627021937909')}}
            </p>
            <div class="btnBox">
                <div class="btnItem" @click="jumpToAddContactsOrCustomer('BF001')">
                    <!-- 新增客户 -->
                    {{$t('mx_mobile.Client.1585300521873')}}
                </div>
                <div class="btnItem" @click="jumpToAddContactsOrCustomer('BF003')">
                    <!-- 新增联系人 -->
                    {{$t('mx_mobile.Client.1585300691680')}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import titleMixin from '@/mixin/title'
export default {
    inheritAttrs: false,
    name: 'StrangeCustomer',
    mixins: [titleMixin],
    title: '客户信息',
    data() {
        return {
            name: '',
            mailAddress: ''
        }
    },
    created() {

    },
    mounted() {
        let { name, mailAddress } = this.$route.params
        if (!mailAddress) {
            this.$router.go(-1)
        }
        this.mailAddress = mailAddress
        this.name = name
        this.setMenu()
    },
    beforeDestroy() { },

    methods: {
        setMenu() {
            this.Global.utils.rightMenu.clearMenu()
        },
        jumpToAddContactsOrCustomer(moduleCode) {
            // let data = {
            //     key: 'mailAddress',
            //     value: [this.mailAddress]
            // }
            let data = [{
                key: 'contName',
                value: this.name
            }, {
                key: 'mailAddress',
                value: [this.mailAddress]
            }]
            this.Global.utils.setItem(`addObjBF003`, JSON.stringify(data))
            this.$router.replace(`/client/add/${moduleCode}`)
        }

    },
    components: {

    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
.StrangeCustomer {
    display: flex;
    justify-content: center;
    align-items: center;
    .hintBox {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .warnIcon {
        .font-size(50px);
        color: #dddddd;
        .margin-bottom(5px);
    }
    .mailShow {
        color: #529ad5;
    }
    .hintText {
        text-align: center;
    }

    .btnBox {
        .margin-top(10px);
        display: flex;
    }
    .btnItem {
        .height(38px);

        background-color: #d0021b;
        color: #fff;
        .line-height(38px);
        .padding(0, 10px);
        .border-radius(3px);
        &:nth-of-type(1) {
            .margin-right(15px);
        }
    }
}
</style>
